html {
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Noto Sans',
    Helvetica, Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji';
}

h1,
h2,
h3,
h4 {
  text-wrap: balance;
}

p {
  text-wrap: pretty;
}

main {
  margin: 0 auto;
}

.button {
  border: 0;
  display: block;
  text-decoration: none;
  background: #000;
  color: white;
  font-size: 16px;
  text-transform: uppercase;
  padding: 20px;
  font-weight: 600;
  font-family: 'Dela Gothic One', Helvetica, sans-serif;
  letter-spacing: 3px;
  cursor: pointer;
}

section.ready-cta {
  display: flex;
  flex-direction: column;

  h2 {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  .pink-button {
    margin: 0 auto;
    background: #ff3e95;
  }
}

section.top {
  background-color: #ea3059;
  background-image: url('./images/header-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  flex-direction: column;
  // height: 430px;
  // justify-content: center;
  padding: 40px 0 70px 0;

  img {
    display: block;
    margin: 0 auto 12px auto;
  }

  h1 {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 50px;
    color: white;
    text-align: center;
    text-transform: uppercase;
    line-height: 1;
    margin-bottom: 28px;
    letter-spacing: 1px;
  }

  p {
    font-size: 20px;
    font-family: 'Montagu Slab', serif;
    color: white;
    max-width: 600px;
    margin: 0 auto 28px auto;
    text-align: center;
    padding: 0 20px;
    line-height: 1.3;
  }

  a {
    margin: 0 auto;
  }
}

section.howto {
  display: flex;
  flex-direction: column;
  padding: 40px 0 20px 0;

  h2 {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    margin: 0 auto 36px auto;
    font-family: 'Montagu Slab', serif;
    text-align: center;
    padding: 0 20px;
  }

  .steps {
    max-width: 800px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;

    img {
      object-fit: cover;
      object-position: 0 0;
      display: block;
      margin: 0 auto 24px auto;
    }

    h3 {
      font-family: 'Poppins', Helvetica, sans-serif;
      font-size: 30px;
      line-height: 1;
      text-transform: uppercase;
      text-align: center;
      margin-bottom: 8px;
    }
  }
}

section.share {
  background-color: #ed4d75;
  background-image: url('./images/share-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 100px 0;

  @media only screen and (min-width: 960px) {
    display: none;
  }

  h2 {
    color: white;
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
  }

  h3 {
    font-family: 'Montagu Slab', serif;
    font-size: 16px;
    color: white;
    margin-bottom: 40px;
    text-align: center;
  }

  .share-button {
    margin: 0 auto;
    appearance: none;
  }
}

section.creators {
  background: black;
  padding: 80px 0 100px 0;

  h2 {
    color: white;
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    padding: 0 20px;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 4px;
  }

  h3 {
    color: white;
    font-family: 'Montagu Slab', serif;
    font-size: 25px;
    text-align: center;
    margin-bottom: 16px;
  }

  .people {
    margin: 0 auto;
    max-width: 960px;
    display: flex;
    flex-direction: column;

    @media only screen and (min-width: 960px) {
      flex-direction: row;

      div {
        flex-basis: 320px;
      }
    }

    img.pfp {
      display: block;
      margin: 0 auto 20px auto;
    }

    h4 {
      text-transform: uppercase;
      color: white;
      font-family: 'Poppins', Helvetica, sans-serif;
      font-size: 25px;
      text-align: center;
    }

    p {
      font-family: 'Montagu Slab', serif;
      font-size: 18px;
      color: white;
      text-align: center;
      max-width: 420px;
      margin: 0 auto 12px auto;
      padding: 0 16px;
    }
  }

  .socials {
    display: flex;
    gap: 8px;
    justify-content: center;
    margin-bottom: 20px;
  }
}

section.phonenumber {
  padding: 50px 0 40px 0;

  h2 {
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    margin: 0 auto 36px auto;
    font-family: 'Montagu Slab', serif;
    text-align: center;
    padding: 0 20px;
  }

  div.input {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    span {
      font-family: 'Montagu Slab', serif;
      font-size: 30px;
    }
  }

  input[type='tel'] {
    border: 3px solid black;
    border-radius: 0;
    appearance: none;
    font-size: 22px;
    padding: 4px 8px;
    width: 280px;
  }
}

section.record {
  background-color: #ea3059;
  background-image: url('./images/header-bg.png');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  padding: 30px 0 80px 0;

  h2 {
    color: white;
    font-family: 'Poppins', Helvetica, sans-serif;
    font-size: 35px;
    line-height: 1;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 16px;
  }

  p {
    color: white;
    margin: 0 auto 36px auto;
    font-family: 'Montagu Slab', serif;
    font-size: 18px;
    text-align: center;
    padding: 0 16px;
    max-width: 600px;
  }

  .record-button {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    align-items: center;
    background: 0;
    border: 0;
    margin-bottom: 8px;
    position: relative;
    cursor: pointer;

    /*
     * Remove focus styles for non-keyboard focus.
     */
    :focus:not(:focus-visible) {
      outline: 0;
      box-shadow: none;
    }

    span {
      color: black;
      font-family: 'Poppins', Helvetica, sans-serif;
      font-size: 18px;
      text-transform: uppercase;
    }

    img {
      object-fit: cover;
      object-position: 0 0;
      transition: transform 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }

    img.loading-mic {
      transform: scale(0.65);
    }

    img.stop {
      object-position: -240px 0;
    }

    img.play {
      object-position: -480px 0;
    }

    img.pause {
      object-position: -720px 0;
    }
  }

  p.yay {
    font-size: 20px;
    margin-bottom: 24px;
  }

  button[type='submit'] {
    margin: 0 auto;
    min-width: 220px;

    &:disabled {
      opacity: 0.8;
    }
  }
}

form.send {
  .stop-button,
  .play-button {
    display: block;
    appearance: none;
  }
}

body.share-page {
  height: 100vh;

  main {
    background-color: #ea3059;
    background-image: url('./images/header-bg.png');
    background-repeat: repeat;
    padding: 0 0 80px 0;
    height: 100vh;
    display: flex;
    min-height: 100vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    img {
      display: block;
      margin: 0 auto 12px auto;
    }

    h1 {
      font-family: 'Poppins', Helvetica, sans-serif;
      font-size: 50px;
      color: white;
      text-align: center;
      text-transform: uppercase;
      line-height: 1;
      margin-bottom: 28px;
      letter-spacing: 1px;
    }

    p {
      font-size: 20px;
      font-family: 'Montagu Slab', serif;
      color: white;
      max-width: 600px;
      margin: 0 auto 28px auto;
      text-align: center;
      padding: 0 20px;
    }

    button {
      margin: 0 auto;
    }
  }
}

.fun {
  position: relative;
  transition: transform 0.3s ease-in-out;
}

// Apply hover styles to desktop
@media only screen and (min-width: 769px) {
  .fun:hover {
    transform: scale(1.05);
  }
}

.heart-particle {
  opacity: 0;
  position: absolute;
  animation: hearts 3s ease-in infinite;
  pointer-events: none;
}

@keyframes hearts {
  0% {
    opacity: 0;
    transform: translate(0, 0%) rotate(0deg);
  }
  20% {
    opacity: 0.8;
    transform: translate(0, -20%) rotate(0deg);
  }
  100% {
    opacity: 0;
    transform: translate(0, -1000%) rotate(0deg);
  }
}

.pulse {
  height: 240px;
  width: 240px;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
  animation: pulse-animation 2s infinite;
  pointer-events: none;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.3);
  }
  100% {
    box-shadow: 0 0 0 30px rgba(0, 0, 0, 0);
  }
}

.shake {
  animation: shake-animation 2s ease-in-out infinite;
}

@keyframes shake-animation {
  0%,
  10%,
  65%,
  100% {
    transform: rotate(0deg) scale(1);
  }

  30%,
  40% {
    transform: rotate(-1deg) scale(1.05);
  }
  35%,
  45% {
    transform: rotate(1deg) scale(1.05);
  }
}
