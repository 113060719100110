html {
  box-sizing: border-box;
}

*, :before, :after {
  box-sizing: inherit;
}

* {
  margin: 0;
  padding: 0;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Noto Sans, Helvetica, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji;
}

h1, h2, h3, h4 {
  text-wrap: balance;
}

p {
  text-wrap: pretty;
}

main {
  margin: 0 auto;
}

.button {
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 3px;
  cursor: pointer;
  background: #000;
  border: 0;
  padding: 20px;
  font-family: Dela Gothic One, Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 600;
  text-decoration: none;
  display: block;
}

section.ready-cta {
  flex-direction: column;
  display: flex;
}

section.ready-cta h2 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.ready-cta .pink-button {
  background: #ff3e95;
  margin: 0 auto;
}

section.top {
  background-color: #ea3059;
  background-image: url("header-bg.6935b27a.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  padding: 40px 0 70px;
  display: flex;
}

section.top img {
  margin: 0 auto 12px;
  display: block;
}

section.top h1 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 28px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 50px;
  line-height: 1;
}

section.top p {
  color: #fff;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 28px;
  padding: 0 20px;
  font-family: Montagu Slab, serif;
  font-size: 20px;
  line-height: 1.3;
}

section.top a {
  margin: 0 auto;
}

section.howto {
  flex-direction: column;
  padding: 40px 0 20px;
  display: flex;
}

section.howto h2 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.howto p {
  text-align: center;
  margin: 0 auto 36px;
  padding: 0 20px;
  font-family: Montagu Slab, serif;
}

section.howto .steps {
  flex-direction: column;
  max-width: 800px;
  margin: 0 auto;
  display: flex;
}

section.howto .steps img {
  object-fit: cover;
  object-position: 0 0;
  margin: 0 auto 24px;
  display: block;
}

section.howto .steps h3 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 8px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 30px;
  line-height: 1;
}

section.share {
  background-color: #ed4d75;
  background-image: url("share-bg.dc7a08dd.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 100px 0;
}

@media only screen and (width >= 960px) {
  section.share {
    display: none;
  }
}

section.share h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.share h3 {
  color: #fff;
  text-align: center;
  margin-bottom: 40px;
  font-family: Montagu Slab, serif;
  font-size: 16px;
}

section.share .share-button {
  appearance: none;
  margin: 0 auto;
}

section.creators {
  background: #000;
  padding: 80px 0 100px;
}

section.creators h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 4px;
  padding: 0 20px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.creators h3 {
  color: #fff;
  text-align: center;
  margin-bottom: 16px;
  font-family: Montagu Slab, serif;
  font-size: 25px;
}

section.creators .people {
  flex-direction: column;
  max-width: 960px;
  margin: 0 auto;
  display: flex;
}

@media only screen and (width >= 960px) {
  section.creators .people {
    flex-direction: row;
  }

  section.creators .people div {
    flex-basis: 320px;
  }
}

section.creators .people img.pfp {
  margin: 0 auto 20px;
  display: block;
}

section.creators .people h4 {
  text-transform: uppercase;
  color: #fff;
  text-align: center;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 25px;
}

section.creators .people p {
  color: #fff;
  text-align: center;
  max-width: 420px;
  margin: 0 auto 12px;
  padding: 0 16px;
  font-family: Montagu Slab, serif;
  font-size: 18px;
}

section.creators .socials {
  justify-content: center;
  gap: 8px;
  margin-bottom: 20px;
  display: flex;
}

section.phonenumber {
  padding: 50px 0 40px;
}

section.phonenumber h2 {
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.phonenumber p {
  text-align: center;
  margin: 0 auto 36px;
  padding: 0 20px;
  font-family: Montagu Slab, serif;
}

section.phonenumber div.input {
  justify-content: center;
  align-items: center;
  gap: 4px;
  display: flex;
}

section.phonenumber div.input span {
  font-family: Montagu Slab, serif;
  font-size: 30px;
}

section.phonenumber input[type="tel"] {
  appearance: none;
  border: 3px solid #000;
  border-radius: 0;
  width: 280px;
  padding: 4px 8px;
  font-size: 22px;
}

section.record {
  background-color: #ea3059;
  background-image: url("header-bg.6935b27a.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  padding: 30px 0 80px;
}

section.record h2 {
  color: #fff;
  text-transform: uppercase;
  text-align: center;
  margin-bottom: 16px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 35px;
  line-height: 1;
}

section.record p {
  color: #fff;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 36px;
  padding: 0 16px;
  font-family: Montagu Slab, serif;
  font-size: 18px;
}

section.record .record-button {
  cursor: pointer;
  background: 0;
  border: 0;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 8px;
  display: flex;
  position: relative;
}

section.record .record-button :focus:not(:focus-visible) {
  box-shadow: none;
  outline: 0;
}

section.record .record-button span {
  color: #000;
  text-transform: uppercase;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 18px;
}

section.record .record-button img {
  object-fit: cover;
  object-position: 0 0;
  transition: transform .5s cubic-bezier(.175, .885, .32, 1.275);
}

section.record .record-button img.loading-mic {
  transform: scale(.65);
}

section.record .record-button img.stop {
  object-position: -240px 0;
}

section.record .record-button img.play {
  object-position: -480px 0;
}

section.record .record-button img.pause {
  object-position: -720px 0;
}

section.record p.yay {
  margin-bottom: 24px;
  font-size: 20px;
}

section.record button[type="submit"] {
  min-width: 220px;
  margin: 0 auto;
}

section.record button[type="submit"]:disabled {
  opacity: .8;
}

form.send .stop-button, form.send .play-button {
  appearance: none;
  display: block;
}

body.share-page {
  height: 100vh;
}

body.share-page main {
  background-color: #ea3059;
  background-image: url("header-bg.6935b27a.png");
  background-repeat: repeat;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  min-height: 100vh;
  padding: 0 0 80px;
  display: flex;
}

body.share-page main img {
  margin: 0 auto 12px;
  display: block;
}

body.share-page main h1 {
  color: #fff;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
  margin-bottom: 28px;
  font-family: Poppins, Helvetica, sans-serif;
  font-size: 50px;
  line-height: 1;
}

body.share-page main p {
  color: #fff;
  text-align: center;
  max-width: 600px;
  margin: 0 auto 28px;
  padding: 0 20px;
  font-family: Montagu Slab, serif;
  font-size: 20px;
}

body.share-page main button {
  margin: 0 auto;
}

.fun {
  transition: transform .3s ease-in-out;
  position: relative;
}

@media only screen and (width >= 769px) {
  .fun:hover {
    transform: scale(1.05);
  }
}

.heart-particle {
  opacity: 0;
  pointer-events: none;
  animation: 3s ease-in infinite hearts;
  position: absolute;
}

@keyframes hearts {
  0% {
    opacity: 0;
    transform: translate(0)rotate(0);
  }

  20% {
    opacity: .8;
    transform: translate(0, -20%)rotate(0);
  }

  100% {
    opacity: 0;
    transform: translate(0, -1000%)rotate(0);
  }
}

.pulse {
  pointer-events: none;
  border-radius: 50%;
  width: 240px;
  height: 240px;
  animation: 2s infinite pulse-animation;
  position: absolute;
  bottom: 0;
}

@keyframes pulse-animation {
  0% {
    box-shadow: 0 0 #0000004d;
  }

  100% {
    box-shadow: 0 0 0 30px #0000;
  }
}

.shake {
  animation: 2s ease-in-out infinite shake-animation;
}

@keyframes shake-animation {
  0%, 10%, 65%, 100% {
    transform: rotate(0)scale(1);
  }

  30%, 40% {
    transform: rotate(-1deg)scale(1.05);
  }

  35%, 45% {
    transform: rotate(1deg)scale(1.05);
  }
}

/*# sourceMappingURL=index.80aca221.css.map */
